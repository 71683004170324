import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SubscribedComponent } from './shared/components/subscribed.component';
import { PreloaderService } from '@services/preloader.service';
import { filter, takeUntil } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { watchEntity } from './utils/entity-watcher.util';
import { Store } from '@services/store';
import { DateConfigService } from '@services/date-config.service';
import { DateTimeUtils } from './lib/date-time-utils';
import { App } from '@capacitor/app';

App.addListener('appUrlOpen', (data: { url: string }) => {
  console.log('App opened with URL:', data.url);
  if (data.url.startsWith('com.timetailor.app://stripe-auth')) {
    const queryString = data.url.split('?')[1];
    if (queryString) {
      const params = new URLSearchParams(queryString);
      const authCode = params.get('code');
      console.log('Stripe auth code:', authCode);
      // Handle the authentication code or any other info
    }
  }
});
@Component({
  selector: 'app-root',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NzLayoutModule, NzSpinModule, RouterOutlet, LoadingComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent extends SubscribedComponent implements OnInit, AfterViewInit {
  title = 'Appointments Taking App';

  constructor(
    private readonly router: Router,
    private readonly preloader: PreloaderService,
    private readonly authService: AuthService,
    private store: Store,
    private dateConfigService: DateConfigService,
    private dateTimeUtils: DateTimeUtils,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.scrollToTop();
    this.watchEntities();

    // check if user is (still) authenticated
    this.authService.getProfile(true).pipe(takeUntil(this.destroyed$)).subscribe();
  }

  scrollToTop(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        window.scrollTo(0, 0);
      });
  }

  watchEntities(): void {
    watchEntity(this.store.watchCurrentLocation(), this.destroyed$, (location) => {
      if (!location) return;
      const firstDayOfWeek = this.dateTimeUtils.getWeekStartForCountry(location.countryCode); // Returns 0 for Sunday, 1 for Monday
      this.dateConfigService.updateDateConfig(firstDayOfWeek);
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.preloader.removePreLoader();
  }
}
