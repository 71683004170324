import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Capacitor } from '@capacitor/core';

export const FACEBOOK_APP_ID = new InjectionToken<string>('FACEBOOK_APP_ID');

@Injectable({
  providedIn: 'root',
})
export class FacebookSdkService {
  private sdkLoaded = false;
  private sdkLoadingPromise: Promise<void> | null = null;

  constructor(@Inject(FACEBOOK_APP_ID) private appId: string) {}

  loadSdk(version: string = 'v12.0'): Promise<void> {
    // If on a native platform, no need to load the JS SDK.
    if (Capacitor.isNativePlatform()) {
      return Promise.resolve();
    }

    if (this.sdkLoaded) {
      return Promise.resolve();
    }

    if (this.sdkLoadingPromise) {
      return this.sdkLoadingPromise;
    }

    this.sdkLoadingPromise = new Promise((resolve, reject) => {
      // Set up fbAsyncInit to initialize the SDK when the script loads.
      (window as any).fbAsyncInit = () => {
        try {
          FB.init({
            appId: this.appId,
            cookie: true,
            xfbml: true,
            version: version,
          });
          FB.AppEvents.logPageView();
          this.sdkLoaded = true;
          resolve();
        } catch (error) {
          reject('Facebook SDK initialization failed');
        }
      };

      const scriptId = 'facebook-jssdk';
      const existingScript = document.getElementById(scriptId);

      if (!existingScript) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
        script.async = true;
        script.defer = true;
        script.onerror = () => reject('Failed to load Facebook SDK script');
        document.body.appendChild(script);
      } else if (this.sdkLoaded) {
        resolve();
      }
      // Otherwise, wait for fbAsyncInit to be invoked.
    });

    return this.sdkLoadingPromise;
  }
}
