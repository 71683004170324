import { Injectable, Inject, InjectionToken } from '@angular/core';
import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
export const APPLE_AUTH_CLIENT_ID = new InjectionToken<string>('APPLE_AUTH_CLIENT_ID');
export const APPLE_REDIRECT_URI = new InjectionToken<string>('APPLE_REDIRECT_URI');

export interface AppleAuthResponse {
  accessToken: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppleAuthService {
  constructor(
    @Inject(APPLE_AUTH_CLIENT_ID) private readonly clientId: string,
    @Inject(APPLE_REDIRECT_URI) private readonly redirectUri: string,
  ) {}

  async login(): Promise<AppleAuthResponse | null> {
    const options: SignInWithAppleOptions = {
      clientId: this.clientId,
      redirectURI: this.redirectUri,
      scopes: 'email name',
    };

    try {
      const appleResponse: SignInWithAppleResponse = await SignInWithApple.authorize(options);
      const { identityToken, givenName, familyName, email } = appleResponse.response;

      let name: string | undefined = undefined;
      if (givenName || familyName) {
        name = [givenName, familyName].filter(Boolean).join(' ');
      }
      const authResponse: AppleAuthResponse = {
        accessToken: identityToken,
        name,
      };
      return authResponse;
    } catch (error) {
      console.error('Apple login error:', error);
      return null;
    }
  }

  async logout(): Promise<void> {
    // Apple doesn't provide a standard sign out method via the SDK.
    // Clear session information as appropriate in your application.
    console.warn('Apple Sign Out is not implemented in this plugin. Please handle session clearing locally.');
  }
}
