import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@Pipe({
  name: 'utcToLocalDate',
  standalone: true,
})
export class UtcToLocalDatePipe implements PipeTransform {
  transform(utcDate: Date | string, timeZone: string): Date {
    if (!utcDate || !timeZone) return null;

    let timeInTz;

    if (typeof utcDate === 'string' && utcDate.includes('T')) {
      // Case 1: Full UTC datetime string (e.g., "2025-01-31T16:15:00.000Z")
      timeInTz = dayjs.utc(utcDate).tz(timeZone);
    } else if (typeof utcDate === 'string') {
      // Case 2: Time-only string (e.g., "16:15:00"), assume today’s date in the target timezone
      timeInTz = dayjs.utc(`${dayjs().format('YYYY-MM-DD')} ${utcDate}`).tz(timeZone);
    } else {
      // Case 3: If it's already a Date object, treat it as UTC
      timeInTz = dayjs.utc(utcDate).tz(timeZone);
    }

    // Create a Date object without shifting the timezone
    return new Date(
      timeInTz.year(),
      timeInTz.month(),
      timeInTz.date(),
      timeInTz.hour(),
      timeInTz.minute(),
      timeInTz.second(),
      timeInTz.millisecond(),
    );
  }
}
