import { Injectable } from '@angular/core';
import {
  AuthUser,
  LoginPayload,
  LoginResponse,
  RegisterPayload,
  SocialLoginPayload,
  SocialRegisterPayload,
} from '@services/api/auth-api.types';
import { tap } from 'rxjs/internal/operators/tap';
import { StorageKey, WindowService } from '@services/window.service';
import { Store } from '@services/store';
import { AuthApiService } from '@services/api/auth-api.service';
import { lastValueFrom, Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly authApiService: AuthApiService,
    private readonly windowService: WindowService,
    private readonly store: Store,
  ) {}

  login(data: LoginPayload): Observable<AuthUser> {
    return this.authApiService.login(data).pipe(
      tap(({ accessToken, refreshToken }) => {
        this.windowService.setStorage(StorageKey.AccessToken, accessToken);
        this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
      }),
      switchMap(() => this.getProfile()),
    );
  }

  loginWithGoogle(data: SocialLoginPayload): Observable<LoginResponse> {
    return this.authApiService.loginWithGoogle(data).pipe(
      tap((response) => {
        if (response) {
          const { accessToken, refreshToken } = response;
          this.windowService.setStorage(StorageKey.AccessToken, accessToken);
          this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
        }
      }),
    );
  }

  loginWithFacebook(data: SocialLoginPayload): Observable<LoginResponse> {
    return this.authApiService.loginWithFacebook(data).pipe(
      tap((response) => {
        if (response) {
          const { accessToken, refreshToken } = response;
          this.windowService.setStorage(StorageKey.AccessToken, accessToken);
          this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
        }
      }),
    );
  }

  loginWithApple(data: SocialLoginPayload): Observable<LoginResponse> {
    return this.authApiService.loginWithApple(data).pipe(
      tap((response) => {
        if (response) {
          const { accessToken, refreshToken } = response;
          this.windowService.setStorage(StorageKey.AccessToken, accessToken);
          this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
        }
      }),
    );
  }

  refreshToken() {
    const refreshToken = this.windowService.getStorage(StorageKey.RefreshToken);
    return this.authApiService.refreshToken(refreshToken).pipe(
      tap(({ accessToken, refreshToken }) => {
        this.windowService.setStorage(StorageKey.AccessToken, accessToken);
        this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
      }),
    );
  }

  async logout(fromAllDevices: boolean = false) {
    const refreshToken = this.windowService.getStorage(StorageKey.RefreshToken);
    if (refreshToken) {
      // first, request a logout the existing token(s)
      void lastValueFrom(this.authApiService.logout(refreshToken, fromAllDevices));
    }

    // then, clean up the local storage and the store
    this.windowService.removeStorage(StorageKey.AccessToken);
    this.windowService.removeStorage(StorageKey.RefreshToken);
    this.store.removeUser();
  }

  getProfile(checkAuthStatus: boolean = false) {
    return this.authApiService.getProfile(checkAuthStatus).pipe(
      tap((authUser) => {
        this.store.setUser(authUser);
      }),
      catchError((error) => {
        if (checkAuthStatus) {
          this.store.removeUser();
        }

        return throwError(() => error);
      }),
    );
  }

  register(data: RegisterPayload): Observable<AuthUser> {
    return this.authApiService.register(data).pipe(
      tap(({ accessToken, refreshToken }) => {
        this.windowService.setStorage(StorageKey.AccessToken, accessToken);
        this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
      }),
      switchMap(() => this.getProfile()),
    );
  }

  registerWithApple(data: SocialRegisterPayload): Observable<AuthUser> {
    return this.authApiService.registerWithApple(data).pipe(
      tap(({ accessToken, refreshToken }) => {
        this.windowService.setStorage(StorageKey.AccessToken, accessToken);
        this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
      }),
      switchMap(() => this.getProfile()),
    );
  }

  registerWithGoogle(data: SocialRegisterPayload): Observable<AuthUser> {
    return this.authApiService.registerWithGoogle(data).pipe(
      tap(({ accessToken, refreshToken }) => {
        this.windowService.setStorage(StorageKey.AccessToken, accessToken);
        this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
      }),
      switchMap(() => this.getProfile()),
    );
  }

  registerWithFacebook(data: SocialRegisterPayload): Observable<AuthUser> {
    return this.authApiService.registerWithFacebook(data).pipe(
      tap(({ accessToken, refreshToken }) => {
        this.windowService.setStorage(StorageKey.AccessToken, accessToken);
        this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
      }),
      switchMap(() => this.getProfile()),
    );
  }
}
