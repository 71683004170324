const host = 'https://api.timetailor.com';

export const environment = {
  production: true,
  apiUrl: `${host}/`,
  adminUrl: `https://app.timetailor.com`,
  bookingUrl: `https://booking.timetailor.com`,
  version: 'd434b4bd7e9778609a10cb996ce531a143276e6d', // Placeholder for version
  name: 'production',
  uploadCarePublicKey: 'dcc918afd43d13903024',
  appleAuthClientId: 'com.timetailor.applocal',
  appleAuthRedirectUri: `https://app.timetailor.com`,
  googleAuthClientKey: '891482607059-483hcpird9s85m18ndc4is031ls5voo5.apps.googleusercontent.com',
  googleAuthiOSClientId: '891482607059-5pftku8psgo9hoa74f42e9404o4lophh.apps.googleusercontent.com',
  facebookAppId: '1230886921516023',
  facebookClientToken: '43a34b94095beec3af1dc8c8aa93926e',
  signupTypeformUrl: 'https://form.typeform.com/to/eyOKF0Hg',
  intercomAppId: 'v4smzmyf',
  stripePublishableKey:
    'pk_live_51O2fkdCs1Y4LA3vDSoWJA9xPdzAMYruyaS1mA3xfqyMSORyhRuCLazQQOkLZmlNmf2R68V7QaQBA5mLsN4tuIu4r00UIKsHTdy',
  fullCalendarLicenseKey: '0504294289-fcs-1743009350',
};
