import { Inject, Injectable, InjectionToken } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { SafeAny } from 'src/app/types/generic.types';
import { Capacitor } from '@capacitor/core';
import { GoogleLoginResponseOnline, SocialLogin } from '@capgo/capacitor-social-login';
import { environment } from '@env/environment';

// Web types from the Google Identity Services SDK
// @ts-ignore
export type TokenResponse = google.accounts.oauth2.TokenResponse;
// @ts-ignore
export type TokenClient = google.accounts.oauth2.TokenClient;

export const GOOGLE_AUTH_CLIENT_ID = new InjectionToken<string>('GOOGLE_AUTH_CLIENT_ID');

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  // For the web flow:
  private tokenResponse$ = new Subject<TokenResponse>();
  private webReady: Promise<void>;
  private tokenClient: TokenClient;

  // Track if native initialization is complete:
  private nativeInitialized = false;

  constructor(@Inject(GOOGLE_AUTH_CLIENT_ID) private readonly webClientId: string) {
    if (Capacitor.isNativePlatform()) {
      this.initNative().catch((error) => {
        console.error('Error initializing native Google Auth:', error);
      });
    } else {
      this.initWeb();
    }
  }

  async login(): Promise<SafeAny> {
    if (Capacitor.isNativePlatform()) {
      // Native flow:
      if (!this.nativeInitialized) {
        await this.initNative();
      }
      try {
        await SocialLogin.logout({ provider: 'google' });
        const googleLoginResponse = await SocialLogin.login({
          provider: 'google',
          options: {
            scopes: ['email', 'profile'],
          },
        });
        // console.log('Native Google login successful:', googleLoginResponse);
        return { access_token: (googleLoginResponse.result as GoogleLoginResponseOnline).accessToken.token };
      } catch (error) {
        console.error('Native Google login error:', error);
        throw error;
      }
    } else {
      // Web flow:
      await this.webReady;
      this.tokenClient.requestAccessToken();
      return firstValueFrom(this.tokenResponse$);
    }
  }

  /**
   * Initialize the Google Identity Services SDK for web.
   */
  private initWeb() {
    this.webReady = new Promise((resolve) => {
      if ((window as SafeAny).google && (window as SafeAny).google.accounts) {
        this.initializeWebClient(resolve);
      } else {
        (window as SafeAny).onGoogleLibraryLoad = () => {
          this.initializeWebClient(resolve);
        };
      }
    });
  }

  private initializeWebClient(resolve: () => void) {
    // @ts-ignore
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this.webClientId,
      scope: 'profile email',
      prompt: 'consent',
      callback: (tokenResponse: TokenResponse) => {
        this.tokenResponse$.next(tokenResponse);
      },
    });
    resolve();
  }

  /**
   * Explicitly initialize the native Google Auth plugin.
   */
  private async initNative(): Promise<void> {
    try {
      await SocialLogin.initialize({
        google: {
          iOSClientId: environment.googleAuthiOSClientId,
          webClientId: this.webClientId,
        },
      });
      this.nativeInitialized = true;
      // console.log('Native Google Auth initialized');
    } catch (error) {
      console.error('Native Google Auth initialization error:', error);
      throw error;
    }
  }
}
