import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import {
  AppstoreOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  BarChartOutline,
  BorderOuterOutline,
  CalendarOutline,
  CaretDownOutline,
  CaretUpOutline,
  CheckCircleOutline,
  ClockCircleOutline,
  CloseOutline,
  ContactsOutline,
  CopyOutline,
  CreditCardOutline,
  DashboardOutline,
  DeleteOutline,
  DollarOutline,
  DownloadOutline,
  EditOutline,
  EyeOutline,
  FacebookOutline,
  FilterOutline,
  FormOutline,
  GlobalOutline,
  HomeOutline,
  InstagramOutline,
  IdcardOutline,
  LayoutOutline,
  LeftOutline,
  LinkOutline,
  LogoutOutline,
  MailOutline,
  MenuOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  MinusOutline,
  MoreOutline,
  OrderedListOutline,
  PhoneOutline,
  PlusCircleFill,
  PlusCircleOutline,
  PlusOutline,
  ProductOutline,
  ProfileOutline,
  RedoOutline,
  RightOutline,
  SettingOutline,
  ShoppingCartOutline,
  StockOutline,
  UploadOutline,
  TeamOutline,
  UndoOutline,
  UserAddOutline,
  UsergroupAddOutline,
  UserOutline,
  WalletOutline,
  WhatsAppOutline,
  AppleFill,
} from '@ant-design/icons-angular/icons';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { CUSTOM_ICONS } from './custom-icons';

const icons = [
  AppleFill,
  AppstoreOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  BarChartOutline,
  BorderOuterOutline,
  CalendarOutline,
  CaretDownOutline,
  CaretUpOutline,
  CheckCircleOutline,
  ClockCircleOutline,
  CloseOutline,
  ContactsOutline,
  CopyOutline,
  CreditCardOutline,
  DashboardOutline,
  DeleteOutline,
  DollarOutline,
  DownloadOutline,
  EditOutline,
  EyeOutline,
  FacebookOutline,
  FilterOutline,
  FormOutline,
  GlobalOutline,
  HomeOutline,
  InstagramOutline,
  IdcardOutline,
  LayoutOutline,
  LeftOutline,
  LinkOutline,
  LogoutOutline,
  MailOutline,
  MenuOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  MinusOutline,
  MoreOutline,
  OrderedListOutline,
  PhoneOutline,
  PlusCircleFill,
  PlusCircleOutline,
  PlusOutline,
  ProductOutline,
  ProfileOutline,
  RedoOutline,
  RightOutline,
  SettingOutline,
  ShoppingCartOutline,
  StockOutline,
  UploadOutline,
  TeamOutline,
  UndoOutline,
  UserAddOutline,
  UsergroupAddOutline,
  UserOutline,
  WalletOutline,
  WhatsAppOutline,
  ...CUSTOM_ICONS,
];

export function provideNzIcons(): EnvironmentProviders {
  return importProvidersFrom(NzIconModule.forRoot(icons));
}
