import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable({
  providedIn: 'root',
})
export class DateTimeUtils {
  public dayFormat = 'YYYY-MM-DD';
  public timeFormat = 'HH:mm:ss';
  public dayTimeFormat = 'YYYY-MM-DD HH:mm:ss';

  getTodayFromTimezone(timezone: string): Date {
    // get current day from the given timezone
    const todayFromTimezone = dayjs().tz(timezone).startOf('day');
    return todayFromTimezone.toDate();
  }

  getLocalTodayFromTimezone(timezone: string): Date {
    // get current day from the given timezone
    const todayFromTimezone = dayjs().tz(timezone).format(this.dayFormat);
    // switch to local timezone, keeping the same day
    const todayLocal = dayjs(todayFromTimezone).startOf('day');
    return todayLocal.toDate();
  }

  switchTimezone(date: Date | string, timeZone: string): Date {
    return dayjs.tz(this.formatDayTime(date), timeZone).toDate();
  }

  composeDate(day: Date, time: Date): Date {
    const timeDayjs = dayjs(time);
    return dayjs(day)
      .set('hour', timeDayjs.get('hour'))
      .set('minute', timeDayjs.get('minute'))
      .set('second', timeDayjs.get('second'))
      .toDate();
  }

  formatDay(date: Date | string): string {
    return dayjs(date).format(this.dayFormat);
  }

  formatTime(date: Date | string): string {
    return dayjs(date).format(this.timeFormat);
  }

  formatDayTime(date: Date | string): string {
    return dayjs(date).format(this.dayTimeFormat);
  }

  convertMinutesToReadableUnit(minutes: number): string {
    if (minutes >= 43200) {
      return `${Math.round(minutes / 43200)} months`;
    }
    if (minutes % 10080 === 0 && minutes / 10080 <= 52) {
      return `${minutes / 10080} weeks`;
    }
    if (minutes % 1440 === 0 && minutes / 1440 <= 365) {
      return `${minutes / 1440} days`;
    }
    if (minutes % 60 === 0 && minutes / 60 <= 720) {
      return `${minutes / 60} hours`;
    }
    return `${minutes} minutes`;
  }

  convertReadableUnitToMinutes(value: string): number {
    const [amount, unit] = value.split(' ');
    const number = +amount;

    switch (unit.toLowerCase()) {
      case 'minutes':
        return number;
      case 'hours':
        return number * 60;
      case 'days':
        return number * 1440;
      case 'weeks':
        return number * 10080;
      case 'months':
        return number * 43200;
      default:
        throw new Error(`Invalid time unit: ${unit}`);
    }
  }

  getWeekStartForCountry(countryCode: string): number {
    try {
      const locale = new Intl.Locale(`en-${countryCode}`) as any;
      // Returns 0 for Sunday or 1 for Monday
      return locale.weekInfo.firstDay % 7;
    } catch (error) {
      console.error('Intl.Locale not supported or invalid country code', error);
      return 0; // Default to Sunday
    }
  }
}
