import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@Pipe({
  name: 'localDateToUtc',
  standalone: true,
})
export class LocalDateToUtcPipe implements PipeTransform {
  transform(dateInput: string | Date, timeZone: string): Date {
    if (!dateInput || !timeZone) {
      return null;
    }

    const dateString = dateInput instanceof Date ? dayjs.utc(dateInput).format('YYYY-MM-DD HH:mm:ss') : dateInput;

    try {
      const dateInUtc = dayjs.tz(dateString, timeZone).utc().toDate();

      return dateInUtc;
    } catch (error) {
      console.error(`❌ Invalid TimeZone: ${timeZone}`);
      return null;
    }
  }
}
