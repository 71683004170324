import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function watchEntity<T>(
  observable: Observable<T>,
  destroyed$: Subject<void>,
  callback: (data: T) => void,
): void {
  observable.pipe(takeUntil(destroyed$)).subscribe(callback);
}
